import React, { useEffect, useState, useRef } from 'react'
import Texts from 'react-sdk/Texts';
import AppState from '../../js/AppState';

import title_bg from '../../img/video_title_bg.png'

import './PopupVideo.css'

const PopupVideo = () => {

  const vidRef = useRef(null);
  const [showhud, setShowHud] = useState(false);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    if(showhud) {
      let timer = setTimeout(() => setShowHud(false), 1000)

      return () => clearTimeout(timer)
    }
  }, [showhud]);

  useEffect(() => {
    if(paused) {
      vidRef.current.pause()
    }
    else {
      vidRef.current.play()
    }
  }, [paused]);

  function handleClose() {
    AppState.setPopup(null)
    if(AppState.lp_api.args.onClose) AppState.lp_api.args.onClose()
  }

  function handlePause() {
    setPaused(!paused);
  }

  let video_url = Texts.get(AppState.lp_api.args.id)
  let settings = AppState.vertxApi.gameSettings.get()

  return (
    <div className="PopupVideo" onMouseMove={() => setShowHud(true)} onClick={() => handlePause()}>
      <video muted={!settings.audio} playsInline autoPlay onEnded={handleClose} ref={vidRef}>
        <source src={[video_url]} type="video/mp4" />
      </video>
      {showhud && 
        <div className="Title">
            <h1>{Texts.get("title_" + AppState.lp_api.args.id)}</h1>
        </div>
      }
      {showhud && 
        <div className="Skip" onClick={handleClose}>
            <p>{Texts.get("video-skip")}</p>
        </div>
      }
    </div>
  );
};

export default PopupVideo;
