import { observer } from 'mobx-react-lite';
import React, { useRef, useState, useEffect } from 'react';
import AppState, { SCREENS } from '../../js/AppState';
import Texts from "react-sdk/Texts";

import bg_filter from "../../img/bg_filter.png";

import logo from '../../img/logo_fntp.png'

import home_icone from '../../img/home_icone.png'

import './ScreenEnd.css';
const ScreenEnd = () => {

  return (
    <div className="ScreenEnd">
      {/* <img src={bg_filter} /> */}
      <div className="Content">
        {/* <img src={logo}/> */}
        <h1 dangerouslySetInnerHTML={{__html: Texts.get("lp_title")}}/>
        <div className="Buttons">
          <button className="goToMainMenu" onClick={() => AppState.setScreen(SCREENS.HOME)}><img src={home_icone} />{Texts.get("end_goToHome")}</button>
        </div>
      </div>
    </div>
  );
};

export default  observer(ScreenEnd)
