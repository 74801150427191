import { observer } from 'mobx-react-lite';
import React from 'react';
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '../../../js/AppState';
import './Header.css';

const Header = ({children}) => {

  function handleLogout() {
    // AppState.vertxApi.logout()
    // .then(() => AppState.vertxApi.anonymousAutoconnect())
  }

  let user = AppState.vertxApi.user.getConnectedUser()

  // console.log("user", AppState.vertxApi.user)

  return (
    <div className="Header">
      <h1>{Texts.get("app_title")}</h1>

      {children}
      <div className="spacer" />


      <div className="language-selector">
        <select value={AppState.language} onChange={e => AppState.setLanguage(e.target.value)}>
          {window.CONFIG.languages.map(l =>
          <option value={l} key={l}>{l}</option>)}
        </select>
      </div>
      {user && user.isAnon && <span>Connecté en anonymous</span>}

      {user && !user.isAnon &&
      <div className="user">
        {user.pseudo || user.Login}
        <button onClick={handleLogout}>{Texts.get("user-logout")}</button>
      </div>}

      {(!user || user.isAnon) &&
      <div className="user">
        <button onClick={() => AppState.setScreen(SCREENS.LOGIN)}>{Texts.get("user-login")}</button>
      </div>}
    </div>
  );
};

export default  observer(Header)
