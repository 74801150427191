import { observer } from 'mobx-react-lite';
import React, { useRef, useState, useEffect } from 'react';
import AppState, { SCREENS } from '../../js/AppState';
import Texts from "react-sdk/Texts";

import bg_filter from "../../img/bg_filter.png";

import logo from '../../img/logo_fntp.png'

import linear_icon from '../../img/icon_guide.png'
import free_icon from '../../img/icon_libre.png'

import './ScreenHome.css';
const ScreenHome = () => {

  function launchGameWithMode(mode)
  {
    AppState.currentMode = mode
    AppState.setScreen(SCREENS.GAME)
  }

  return (
    <div className="ScreenHome">
      {/* <img src={bg_filter} /> */}
      <div className="Content">
        <img src={logo}/>
        <h1 dangerouslySetInnerHTML={{__html: Texts.get("lp_title")}}/>
        <h2>{Texts.get("lp_description")}</h2>

      <div className="Buttons">
        <button className="linear" onClick={() => launchGameWithMode("linear")}><img src={linear_icon} />{Texts.get("lp_linear")}</button>
        <button className="free" onClick={() => launchGameWithMode("free")}><img src={free_icon} />{Texts.get("lp_free")}</button>
      </div>

      </div>
      <div className="Start">
        <button onClick={() => AppState.setScreen(SCREENS.GAME)}>Lancer</button>
      </div>
    </div>
  );
};

export default  observer(ScreenHome)
