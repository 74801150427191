import { makeAutoObservable } from "mobx";
import DataLoading from 'react-sdk/DataLoading';
import Texts from "react-sdk/Texts";
import Personnages from "react-sdk/Personnage/Personnages";
import Dialogues from "react-sdk/Personnage/Dialogues";
import ScaleManager from "react-sdk/ScaleManager";
import LP_api from "./LP_api";


function getUrlVar(name) {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
}

export const SCREENS = {
  LOADING: "LOADING",
  HOME: "HOME",
  END: "END",
  LOGIN: "LOGIN",
  GAME: "GAME",
  CHAT: "CHAT"
}


export const POPUPS = {
  MENTION: "MENTION",
  TRAILER: "TRAILER",
  SETTINGS: "SETTINGS",
  VIDEO: "VIDEO"
}

class AppState {
  debugMode = false
  gameReady = false
  language = "fr"
  screen = SCREENS.LOADING

  currentMode = "linear"

  lp_api = null
  iframeKey = 0

  popup = null


  vertxApi = null


  constructor() {

    this.debugMode = getUrlVar("debug")

    ScaleManager.init(1920, 1080)

    this.lp_api = new LP_api()

    let vertxconfig = window.VertxConfig.createFromObjet(window.CONFIG.vertx)
    this.vertxApi = window.VertxApi.create(vertxconfig)


    DataLoading.getAllFiles(window.CONFIG.requiredFiles, window.CONFIG.root)

    .then(files => {
        Texts.init(files.texts, this.language)
      })
      .then(() => {
        return new Promise( resolve => {
          this.vertxApi.user.autoConnect((res, data) => {
            if(!res.status) {
              Notifications.error("Erreur de connexion au serveur (" + res.statusMessage + ")", 3000)
            }
            resolve()
          })
        })
      })
      .then(() => this.setScreen(SCREENS.HOME))


    makeAutoObservable(this, { vertxApi: false })
  }

  setScreen(id) {
    this.vertxApi.analytics.score("mode", this.currentMode)

    this.vertxApi.analytics.navigationLeave("screen." + this.screen)
    this.vertxApi.analytics.navigationEnter("screen." + id)

    this.screen = id
  }

  setPopup(popup_id) {
    if (popup_id) {
      this.vertxApi.analytics.navigationEnter("popup." + popup_id)
    } else {
      this.vertxApi.analytics.navigationLeave("popup." + this.popup)
    }
    this.popup = popup_id
  }

  setLanguage(l) {
    this.language = l
    Texts.setLanguage(l)
  }

  refreshIframe() {
    this.iframeKey++
  }
}


export default new AppState()