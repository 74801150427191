import React, { useEffect, useRef } from 'react'
import AppState, { SCREENS } from '../../js/AppState';
import { observer } from 'mobx-react-lite';

import './ScreenGame.css';

const ScreenGame = () => {

  const iframeRef = useRef(null)

  useEffect(() => {
    iframeRef.current.contentWindow.LP_API = AppState.lp_api
  }, [AppState.iframeKey]);

  let game_url = window.CONFIG.game_url + window.location.search + "?mode=" + AppState.currentMode

  return (
    <div className={"ScreenGame" + (AppState.screen === SCREENS.GAME ? " visible" : "")}>
      <iframe key={AppState.iframeKey} src={game_url} ref={iframeRef} />
    </div>
  );
};

export default observer(ScreenGame)
