import React, { useState } from 'react'
import Texts from 'react-sdk/Texts';
import AppState from '../../js/AppState';
import Popup from '../shared/Popup/Popup';

import './SettingsPopup.css'

const Switch = ({initialValue, onChange}) => {
  const [checked, setChecked] = useState(initialValue);

  function handleChange(_checked) {
    setChecked(_checked)
    onChange(_checked)

  }

  return (
    <label className={'Switch' + (checked ? " checked" : "")}>
    <input type="checkbox" checked={checked} onChange={e => handleChange(e.target.checked)} />
    {checked && <span>ON</span>}
    {!checked && <span>OFF</span>}
  </label>)
}

const SettingsPopup = () => {

  function handleClose() {
    AppState.setPopup(null)
    if(AppState.lp_api.args.onClose) AppState.lp_api.args.onClose()
  }

  function handleChange(key, value) {
    let newvars = {...AppState.lp_api.args.vars, [key]: value}

    AppState.gameSettings = newvars

    if(AppState.lp_api.args.onChange) AppState.lp_api.args.onChange(newvars)
  }

  let volume_on = !AppState.lp_api.args.vars.isMuted


  return (

    <Popup className="SettingsPopup" title={Texts.get("settings-popup-title")} onClose={handleClose}>
      <div className="SettingsPopup-option">
        {Texts.get("settings-popup-volume")} <Switch initialValue={volume_on} onChange={volume_on => handleChange("isMuted", !volume_on)} />
      </div>
    </Popup>
  );
};

export default SettingsPopup;
