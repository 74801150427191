import React, { useState } from 'react';
import AppState from '../../../js/AppState';

export default ({onDone}) => {

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const [step, setStep] = useState(0);

  const [errorStep2, setErrorStep2] = useState("");

  function handleSubmit(e) {
    e.preventDefault()



    AppState.vertxApi.userResetPasswordStep1(email)
    .then(() => {
      setStep(1)
    })
    .catch(err => setError(err))



  }

  function handleSendNewPassword(e) {
    e.preventDefault()
    const data = new FormData(e.target)


    const code = data.get('code')
    const password = data.get('password')
    const password2 = data.get('password2')
    if(password !== password2) {
      setErrorStep2("2 passwords don't match !")
      return
    }

    AppState.vertxApi.userResetPasswordStep2(email, password, code)
    .then(() => onDone() )
    .catch((err) =>  setErrorStep2(err))


  }

  return (
  <div className='LoginForm'>
    <h2>Reset password</h2>

    {step === 0  &&
    <form onSubmit={handleSubmit}>
      <div>
        <input id="email" placeholder="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
      </div>

      {error && <div className="error">{error}</div>}

      <button>Submit</button>
    </form>}

    {step === 1  &&
    <form onSubmit={handleSendNewPassword}>
      <div><input name="password"  placeholder="New password" type="password"  /></div>
      <div><input name="password2"  placeholder="New password confirmation" type="password"  /></div>
      <div><input name="code"  placeholder="Code" type="text"  /></div>

      {errorStep2 && <div className="error">{errorStep2}</div>}
      <button>Submit</button>
    </form>}


  </div>)
}