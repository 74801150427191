import React, { useState, useEffect } from 'react'
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '../../js/AppState';
import Popup from '../shared/Popup/Popup';

import sound_on from '../../img/sound_on.png'
import sound_off from '../../img/sound_off.png'
import homeIcone from '../../img/home_icone.png'

import './GameButtons.css'

const GameButtons = ({style}) => {

  let settings = AppState.vertxApi.gameSettings.get()
  const [volume_on, setVolumeOn] = useState(settings.audio)

  useEffect(() => {
    console.log(volume_on)

    AppState.vertxApi.gameSettings.set({audio: volume_on })
  }, [volume_on])

  AppState.vertxApi.gameSettings.set({audio: volume_on })

  function returnHome() {
    AppState.setScreen("HOME")
  }

  return (
    <div className="GameButtons" style={style}>
      <div className="ButtonsContainer">
        <div className="SoundBtn">
          <div className="btn"><img src={volume_on? sound_on : sound_off} onClick={() => setVolumeOn(!volume_on)}/></div>
        </div>
        <div className="HomeBtn">
          { AppState.screen === SCREENS.GAME && <div className="btn"><img src={homeIcone} onClick={() => returnHome() }/></div> }
        </div>
      </div>
    </div>
  );
};

export default GameButtons;
