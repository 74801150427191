import { makeId } from "../utils"
import Notifications from "./Notifications"

class Notification {

  constructor(message, duration = 0, type) {
    this.id = makeId()
    this.message = message
    this.type = type

    if(duration > 0) {
      this.timeout = setTimeout(() => {
        this.close()
      }, duration)
    }
  }

  close() {
    if(this.timeout) clearTimeout(this.timeout)

    Notifications.remove(this)


  }

}

export default Notification
