import { observer } from "mobx-react-lite";
import React from "react";
import AppState, { POPUPS, SCREENS } from "../../js/AppState";

import ScaleManager from 'react-sdk/ScaleManager'
import ScreenLoading from "../ScreenLoading/ScreenLoading";
import ScreenHome from "../ScreenHome/ScreenHome";

import '../../fonts/stylesheet.css';
import './App.css'
import ScreenChat from "../ScreenChat/ScreenChat";
import ScreenLogin from "../ScreenLogin/ScreenLogin";
import Notifs from "../shared/Notifs/Notifs";
import ScreenGame from "../ScreenGame/ScreenGame";
import ScreenEnd from "../ScreenEnd/ScreenEnd";
import PopupVideo from "../PopupVideo/PopupVideo";
import SettingsPopup from "../SettingsPopup/SettingsPopup";

import GameButtons from "../GameButtons/GameButtons";


const App = () => {
  return (
    <>
      <div className="App"  style={ScaleManager.style}>
        {AppState.screen === SCREENS.LOADING && <ScreenLoading />}
        {AppState.screen === SCREENS.HOME && <ScreenHome />}
        {AppState.screen === SCREENS.END && <ScreenEnd />}

        {AppState.screen === SCREENS.LOGIN && <ScreenLogin />}

        <Notifs />

        <div className="version">v{process.env.REACT_APP_VERSION}</div>
        {AppState.debugMode && <div className="debug">Debug mode: {AppState.debugMode}</div>}
      </div>

      {AppState.screen === SCREENS.GAME && <ScreenGame />}

      {AppState.popup && <div className="App2"  style={ScaleManager.style}>
        {/* {AppState.popup === POPUPS.MENTION && <PopupMentionsLegales />}
          {AppState.popup === POPUPS.TRAILER && <TrailerPopup />}
        */}
        {AppState.popup === POPUPS.SETTINGS && <SettingsPopup />}
        {AppState.popup === POPUPS.VIDEO && <PopupVideo />}
      </div>}

      <GameButtons style={ScaleManager.style}/>
    </>
  )
}

export default observer(App);
