import { marked } from 'marked'
import mustache from 'mustache'

class Texts {
  all = {}
  language = null

  defaultLanguage = null // utilisé si pas de données avec le current language

  init(texts, language, defaultLanguage) {
    this.all = {}
    this.setLanguage(language)
    this.defaultLanguage = defaultLanguage


    texts.forEach(t => {
      if(t.id) {
        if(this.all[t.id] !== undefined) {
          window.alert("Careful, some texts have the same id (" + t.id + "), check your texts.data file")
        }

        this.all[t.id] = t
      }
    })
  }

  /**
   *
   * @param {string} language l'id du langage tel qu'on peut le trouver dans les colonnes du .data
   */
  setLanguage(language) {
    this.language = language
  }



  /**
   * Permet de récupérer une chaine de caractères depuis le fichier .data à l'aide d'une id.
   * La chaine peut être traduite, contenir des variables placeholder, voire du markdown
   * @param {string} id l'id de la ligne dans le fichier .data
   * @param {object} options un objet contenant des options
   * @param {string} options.mustacheVars - Contient les variables de mustache
   * @param {boolean} options.parseMarkdown - Faut-il utiliser le Markdown ?
   * @param {object} options.markdownOptions - Les options du markdown (cf doc de Marked)
   * @param {object} options.language - Permet de forcer un langage spécialement pour cette requête

   * @returns {string} la chaine de caractères
   */
  get(id, options = {}) {
    options = { mustacheVars: null, parseMarkdown: false, markdownOptions: {}, ...options}

    let lang = options.language || this.language

    let t

    let row = this.all[id]

    if(row === undefined){
      console.warn("The id", id, "doesn't exist in your .data file")
      return id
    }


    if(row[lang]) {
      t = row[lang]
    }
    else {
      console.warn(`The id <${id}> doesn't exist in requested language (${this.language})`)
      if(this.defaultLanguage !== this.language && row[this.defaultLanguage]) { // on essaie le defaultlanguage
        t = row[this.defaultLanguage]
        console.warn(`Using ${this.defaultLanguage} for id <${id}>`)
      }
    }

    if(t !== undefined) {

      if(options.mustacheVars) t = mustache.render(t, options.mustacheVars)

      if(options.parseMarkdown) {
        let opts = { linkTargetBlank: true, ...options.markdownOptions}
        const renderer = new marked.Renderer();
        if(opts.linkTargetBlank) {
          const linkRenderer = renderer.link;
          renderer.link = (href, title, text) => linkRenderer.call(renderer, href, title, text).replace(/^<a /, '<a target="_blank" rel="nofollow" ') }
        if(opts.noParagraph) { renderer.paragraph = text => text + '\n' }

        t = marked(t, {renderer})
      }

      return t
    }
    // ici on n'a ni le texte dans la langue demandée, ni dans la langue par défaut...
    // possiblement dans une autre langue ?

    return id
  }


}


export default new Texts()