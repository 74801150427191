import { makeObservable, observable } from "mobx"
import { pickRandom, randomRange } from "../utils"


// TODO : faire en sorte de pouvoir ajouter un perso à Personnages, au lieu de tous les charger en meme temps dans le .init()
// Le but est de pouvoir ajouter 1 perso et le load en "synchrone", puis quand c'est bon, de preload les autres en tache de fond


class Personnage {
  speechlib = null
  reactComponentUpdateCallBack = null

  speedfactor = 1
  soundRef = null

  raf = null
  lastTS = 0

  constructor(json) {
    Object.assign(this, json)
    this.speechlib = new window.SpeechCharacter(this.name, this.options) // https://github.com/Succubus-Interactive/characterlib-succubus

    this.update = this.update.bind(this)
  }

  load() {
    // un personnage est composé de plusieurs attitudes. On charge les json des attitudes, puis on charge les fichiers .basis nécessaires,
    const proms = this.attitudes.map( anim_name =>  this.loadAttitude(anim_name) )
    proms.push(this.loadNeutre())

    return Promise.all(proms)
    .then(() => this.loadAnimRequiredFiles())
  }

  loadNeutre() {
    this.loadAnim("neutre" , this.basePath + this.neutre)
  }

  loadAttitude(anim_name) {
    return this.loadAnim(anim_name + ".pose", this.basePath + anim_name + '/pose.crunch/')
    .then( () => this.loadAnim(anim_name , this.basePath + anim_name + '/transition.crunch/'))
  }

  loadAnim(anim_name, path) {
    const json_path = path + "anim.json";
    return fetch(json_path)
    .then(res => res.text())
    .then(json_txt => {
      // console.log("Add anim", this.name, anim_name)
      this.speechlib.addAnim(anim_name, json_txt, path);
    })
  }

  loadAnimRequiredFiles() {
    let files = this.speechlib.getFilesToLoad()
    // console.log("files", files)
    let promises = files.map(file => {
      return fetch(file.basePath + file.fileName)
      .then(res => res.arrayBuffer())
      .then(buffer => {
        // console.log("file", file.animationName, file.fileName)
        this.speechlib.setAnimData(file.animationName, file.fileName, buffer);
      })
    })
    return Promise.all(promises)
  }


  neutralTimeout = null
  startIdle() {



    let idleAttitudes = window.CONFIG.idleAttitudes || ["interet1", "interet2", "concentration1", "concentration2"]
    // permet d'afficher le perso en idle neutre
    this.startAnim("", "neutre", null, null, null)

    let att = pickRandom(idleAttitudes)


    this.neutralTimeout = setTimeout(() => {
      this.startAnim("", att, null, null, null)

      this.neutralTimeout = setTimeout(() => {
        this.startIdle()
      }, randomRange(6000, 10000))

    }, randomRange(6000, 10000))
  }

  startAnim(text, attitudeTimings, textTimings, howlRef, phonemeContent) {
    if(this.raf) this.stopAnim()

    this.soundRef = howlRef
    let duration = this.soundRef ? this.soundRef.duration() * this.speedfactor : 6

    this.speechlib.setContentRaw(text, attitudeTimings, textTimings , duration, phonemeContent)
    this.lastTS = performance.now()
    this.raf = window.requestAnimationFrame(this.update)
  }

  stopAnim() {
    window.cancelAnimationFrame(this.raf)
    this.raf = null
    this.soundRef = null

    if(this.neutralTimeout) clearTimeout(this.neutralTimeout)
  }

  registerUpdate(reactUpdate) {
    // console.log("registering the update function")
    this.reactComponentUpdateCallBack = reactUpdate
  }

  update(currentTS) {
    let dt = (currentTS - this.lastTS)  / 1000 * this.speedfactor
    let currentTime = this.soundRef ? this.soundRef.seek() : 0
    // let currentTime = this.soundRef.seek()


    this.speechlib.update(dt, currentTime) // dt: ms, currentTime: secondes ??

    this.lastTS = currentTS

    if(this.reactComponentUpdateCallBack) {
      this.reactComponentUpdateCallBack(this.speechlib.getBuffer(), this.speechlib.getWidth(), this.speechlib.getHeight())
    }

    this.raf = requestAnimationFrame(this.update)
  }


}


class Personnages {
  all = []

  current = null
  constructor() {
    makeObservable(this, {
      current: observable
    })
  }
  init(json) {
    this.all = json
    .filter(elem => elem.name)
    .map(elem => new Personnage(elem))
  }

  loadAll() {

    // TODO peut-être que charger pous ces fichiers pour plusieurs persos en meme temps ca peut faire planter
    // peut-être gérer une séquence et charger les persos les uns après les autres ?

    let proms = this.all.map(perso => perso.load())
    return Promise.all(proms)
  }

  waitEngineInit() {
    const loop = (resolve) =>  {
      if (window.characterengineInit) resolve()
      else setTimeout(() => loop(resolve), 200)
    }

    return new Promise( resolve =>  loop(resolve) )
  }

  get ids() { return this.current.map(c => c.id)}
  get(id) { return this.all.find(c => c.name === id) }

}


export default new Personnages()
