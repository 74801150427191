import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import AppState, { SCREENS } from '../../js/AppState';
import Header from '../shared/Header/Header';
import ForgotPasswordForm from './forms/ForgotPasswordForm';
import LoginForm from './forms/LoginForm';
import RegisterForm from './forms/RegisterForm';

import './ScreenLogin.css'


export const FORMS = {
  LOGIN: 0,
  REGISTER: 1,
  FORGOT_PWD: 2
}

const ScreenLogin = () => {
  const [form, setForm] = useState(FORMS.LOGIN);

  function leave() {
    AppState.setScreen(SCREENS.HOME)
  }

  return (
    <div className="screen ScreenLogin">
      <Header>
        <button onClick={leave}>Home</button>
      </Header>
      <div className="ScreenLogin-content">
        {form === FORMS.LOGIN && <LoginForm onForgotClick={() => setForm(FORMS.FORGOT_PWD)} />}
        {form === FORMS.FORGOT_PWD && <ForgotPasswordForm onDone={() => setForm(FORMS.LOGIN)} /> }
        {form === FORMS.REGISTER && <RegisterForm onDone={() => setForm(FORMS.LOGIN)} /> }

        <div>
          {form === FORMS.LOGIN && <button className='link-btn' onClick={() => setForm(FORMS.REGISTER)}>Create an account</button>}
          {form !== FORMS.LOGIN && <button className='link-btn' onClick={() => setForm(FORMS.LOGIN)}>Back</button>}
        </div>



      </div>
    </div>
  );
};

export default observer(ScreenLogin)
